import "../../utils/server/firebase";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { navigate } from "gatsby";
import { toast } from "react-toastify";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import Seo from "../../assets/components/seo";
import AuthNegociosLayout from "../../layouts/AuthNegociosLayout";
import { enviarEnlaceLogin } from "../../utils/functions";
import Navbar from "../../assets/components/Navbar";
import Footer from "../../assets/components/Footer";

const LoginNegocios = () => {
  const auth = getAuth();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    url: "http://localhost:8000/negocios",

    handleCodeInApp: true,
    // iOS: {
    //   bundleId: "com.example.ios"
    // },
    // android: {
    //   packageName: "com.example.android",
    //   installApp: true,
    //   minimumVersion: "12"
    // },
  };

  const onSubmit = async () => {
    try {
      if (!data?.email?.trim()) {
        return toast.warning("Todos los datos son obligatorios");
      }
      enviarEnlaceLogin(data.email);
      typeof window !== "undefined" &&
        window.localStorage.setItem("correo", data.email);
      navigate("/negocios/success");
    } catch (error) {
      toast.error(
        error.message === "Firebase: Error (auth/invalid-email)."
          ? "Email invalido"
          : error.message === "Firebase: Error (auth/user-not-found)."
          ? "No se encontró usuario registrado."
          : error.message === "Firebase: Error (auth/wrong-password)."
          ? "La contraseña es incorrecta"
          : error.message
      );
      console.error(error.message);
    }
  };

  return (
    <AuthNegociosLayout>
      <Container maxWidth="md">
        <Navbar />
        <Grid
          container
          justifyContent="center"
          minHeight="100vh"
          alignItems="center"
        >
          <Grid item maxWidth={500}>
            <Card component="form">
              <CardHeader
                title={
                  <Typography variant="h4" component="h1">
                    Iniciar sesión
                  </Typography>
                }
                subheader={
                  <Typography variant="body2" color="GrayText" marginTop={1}>
                    Te haremos llegar un magic link para iniciar sesión. Ingresa
                    el correo electrónico que usaste cuando te registraste en el
                    formulario de compradores y expositores.
                  </Typography>
                }
              />
              <CardContent>
                <Stack direction="column" spacing={2}>
                  <TextField
                    fullWidth
                    label="Correo eléctronico*"
                    variant="standard"
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <Button fullWidth variant="contained" onClick={onSubmit}>
                  Ingresar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </AuthNegociosLayout>
  );
};

export default LoginNegocios;
